import {createApp} from 'vue'
import App from './App.vue'
import {createI18n} from 'vue-i18n'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import languages from './languages/languages'
import request from "./request/request";
import Member from "./member/member";
// request.setAPI('http://192.168.50.93:8090/api/');
request.setAPI('https://api.initap.com/api/');
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";

Sentry.init({
    dsn: "https://b4a3983d06054eb6aa0c25cbd491f2fd@o943425.ingest.sentry.io/5892292",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

const i18n = createI18n(languages);
i18n.global.locale = navigator.language;

const app = createApp(App).use(i18n).use(Vant);
app.config.globalProperties.$member = new Member();
app.config.globalProperties.$request = request;

router.beforeEach((to, from, next) => {
    if (Object.prototype.hasOwnProperty.call(to.query, 'token')) {
        app.config.globalProperties.$member.setToken(to.query.token);
    }
    app.config.globalProperties.$member.load();
    window.document.title = to.meta.title === undefined ?
        '默认标题' :
        i18n.global.t(to.meta.title);

    document.getElementById('main-title').innerText = to.meta['title'] === undefined ?
        '默认标题' :
        i18n.global.t(to.meta.title);

    to.query.from === 'tab' ?
        document.getElementById('go-back').style.display = 'none' :
        document.getElementById('go-back').style.display = 'block';

    to.path !== '/' ?
        document.getElementById('activity-rule').style.display = 'none' :
        document.getElementById('activity-rule').style.display = 'block';

    to.path === '/order/info' ?
        document.getElementById('main-title').style.display = 'none' :
        document.getElementById('main-title').style.display = 'block';
    next();
});

// router.afterEach((to) => {
//     if (to.hash.startsWith('#/invite') && to.path !== '/invite') {
//         router.push('/invite');
//     }
// })

// app.config.globalProperties.title = 'index.header.title';
app.use(router).mount('#app');