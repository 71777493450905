import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Detail from "../views/Detail";
import PointsLog from "../views/PointsLog";
import OrderInfo from "../views/OrderInfo";
import Form from "../views/Form";
import Invite from "../views/Invite";

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'index.meta.title'
    },
    component: Home
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      title: 'detail.meta.title'
    },
    component: Detail
  },
  {
    path: '/logs',
    name: 'PointsLog',
    meta: {
      title: 'points.meta.title'
    },
    component: PointsLog
  },
  {
    path: '/order/info',
    name: 'OrderInfo',
    meta: {
      title: 'order_info.meta.title'
    },
    component: OrderInfo
  },
  {
    path: '/form',
    name: 'Form',
    meta: {
      title: 'form.meta.title'
    },
    component: Form
  },
  {
    path: '/invite',
    name: 'Invite',
    meta: {
      title: 'invite.meta.title'
    },
    component: Invite
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
