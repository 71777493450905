export default {
    index: {
        meta: {
            title: "福利"
        },

    },
    form: {
        meta: {
            title: "问卷调查"
        },

    },
    detail: {
        meta: {
            title: "商品兑换"
        },
    },
    invite: {
        meta: {
            title: "邀请好友"
        },
    },
    order_info: {
        meta: {
            title: "奖励信息"
        },
    },
    home: {
        header: {
            activity_rule: '活动规则'
        }
    },
    points: {
        meta: {
            title: "积分明细"
        },
        total: {
            title: '全部积分',
            details: '积分明细'
        },
        today: {
            title: '今日获取'
        }
    }
}