/**
 * 将两张图片融合到一起
 * @param {string} background 
 * @param {string|object} foreground
 * @param {number} x 
 * @param {number} y 
 * @param {string} fillElement
 * @param {function?} cb
 */
export default function Fusion(background, foreground, x, y, fillElement, cb) {
    this.img = new Image();
    this.img.src = background;
    this.fillElement = fillElement;
    this.cb = cb;

    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');

    let _this = this;
    this.img.onload = () => {
        this.canvas.width = _this.img.width;
        this.canvas.height = _this.img.height;
        _this.ctx.drawImage(_this.img, 0, 0, _this.img.width, _this.img.height);
        _this.draw(foreground, x, y);
    }

    /**
     * 
     * @param {string|object} src
     * @param {int} x 
     * @param {int} y 
     */
    this.draw = function (src, x, y) {
        let _this = this;
        if (typeof src === "string") {
            let foreground = new Image();
            foreground.src = src;
            foreground.onload = function () {
                _this.ctx.drawImage(foreground, x, y, foreground.width, foreground.height);
                let base64 = _this.canvas.toDataURL("image/png");
                let img = document.getElementById(_this.fillElement);
                img.setAttribute('src', base64);
            }
            return
        }

        _this.ctx.drawImage(src, x, y, src.width, src.height);
        let base64 = _this.canvas.toDataURL("image/png");
        let img = document.getElementById(_this.fillElement);
        img.setAttribute('src', base64);
        img.onload = this.cb;
    }

}
