<template>
  <div id="form">
    <van-form @submit="onSubmit">
      <p class="notice">{{desc}}</p>

      <div v-for="(item) in questions" v-bind:key="item.id">
        <h4>{{ item.position }}. {{ item.title }}</h4>
        <van-field v-if="item.question_type !== 'rank'">
          <template #input>
            <van-radio-group v-model="answers[item.id].answer" v-if="item.question_type === 'radio'">
              <van-radio :name="option" v-for="(option) in item.options" v-bind:key="option">{{ option }}</van-radio>
            </van-radio-group>

            <van-checkbox-group v-model="answers[item.id].answer" v-if="item.question_type === 'checkbox'">
              <van-checkbox :name="option" v-for="(option) in item.options" v-bind:key="option">{{
                  option
                }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field name="channelText" v-model="answers[item.id].custom" v-show="item.has_custom_option" label="其他"
                   placeholder="输入"/>
        <div class="rate" v-if="item.question_type === 'rank'">
          <van-rate v-model="answers[item.id].answer"/>
        </div>
      </div>

      <!--      <h4>1. 您是通过什么渠道知道引力加速器的？[单选]</h4>-->
      <!--      <van-field name="channel">-->
      <!--        <template #input>-->
      <!--          <van-radio-group v-model="channel">-->
      <!--            <van-radio name="0">App Store</van-radio>-->
      <!--            <van-radio name="1">安卓市场</van-radio>-->
      <!--            <van-radio name="2">搜索引擎</van-radio>-->
      <!--            <van-radio name="3">朋友/家人/同事/同学推荐</van-radio>-->
      <!--            <van-radio name="4">社交媒体宣传(Twitter, 微博, 公众号)</van-radio>-->
      <!--          </van-radio-group>-->
      <!--        </template>-->
      <!--      </van-field>-->
      <!--      <van-field name="channelText" v-model="channelText" label="其他" placeholder="输入"/>-->

      <!--      <h4>2. 您的性别是？[单选]</h4>-->
      <!--      <van-field name="gender">-->
      <!--        <template #input>-->
      <!--          <van-radio-group v-model="gender" direction="horizontal">-->
      <!--            <van-radio name="0">男</van-radio>-->
      <!--            <van-radio name="1">女</van-radio>-->
      <!--          </van-radio-group>-->
      <!--        </template>-->
      <!--      </van-field>-->


      <!--      <h4>3. 您的年龄是？[单选]</h4>-->
      <!--      <van-field name="ageRange">-->
      <!--        <template #input>-->
      <!--          <van-radio-group v-model="ageRange">-->
      <!--            <van-radio name="0">15岁以下</van-radio>-->
      <!--            <van-radio name="1">15-20岁</van-radio>-->
      <!--            <van-radio name="2">21-25岁</van-radio>-->
      <!--            <van-radio name="3">26-35岁</van-radio>-->
      <!--            <van-radio name="4">35岁以上</van-radio>-->
      <!--          </van-radio-group>-->
      <!--        </template>-->
      <!--      </van-field>-->

      <!--      <h4>4. 最近半年，您每天累计使用互联网大约多⻓时间？（包括电脑、手机、平板电脑、电视等设备上网时⻓）[单选]</h4>-->
      <!--      <van-field name="keepalive">-->
      <!--        <template #input>-->
      <!--          <van-radio-group v-model="keepalive">-->
      <!--            <van-radio name="0">1小时以内</van-radio>-->
      <!--            <van-radio name="1">1-3小时</van-radio>-->
      <!--            <van-radio name="2">3-5小时</van-radio>-->
      <!--            <van-radio name="3">5-8小时</van-radio>-->
      <!--            <van-radio name="4">8-10小时</van-radio>-->
      <!--            <van-radio name="5">10小时以上</van-radio>-->
      <!--          </van-radio-group>-->
      <!--        </template>-->
      <!--      </van-field>-->

      <!--      <h4>5. 有什么期待的功能? [多选]</h4>-->
      <!--      <van-field name="features">-->
      <!--        <template #input>-->
      <!--          <van-checkbox-group v-model="features">-->
      <!--            <van-checkbox name="0">游戏加速</van-checkbox>-->
      <!--            <van-checkbox name="1">专属线路</van-checkbox>-->
      <!--            <van-checkbox name="2">企业定制</van-checkbox>-->
      <!--          </van-checkbox-group>-->
      <!--        </template>-->
      <!--      </van-field>-->
      <!--      <van-field v-model="otherFeatures" name="otherFeatures" label="其他" placeholder="输入"/>-->

      <!--      <h4>5. 您是否愿意推荐身边的人使用引力加速器? </h4>-->
      <!--      <div class="rate"><van-rate v-model="rate" /></div>-->

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>

    <div v-show="complete" class="mask">
      <div class="sale-out"><p>已提交, 感谢您的参与!</p><p>即将返回首页</p></div>
    </div>
  </div>
</template>

<script>
import "@/styles/form.scss";
import {Toast} from "vant";

export default {
  name: "Form",

  data() {
    return {
      questions: [],
      id: '',
      desc: '',
      answers: {},
      oldAnswers: {},
      complete: false
    }
  },

  watch: {
    answers: {
      handler(newValue) {
        for (let key in newValue) {
          if (Object.hasOwnProperty.call(newValue, key)) {
            if ((typeof newValue[key].answer) !== "object") {
              if (newValue[key].custom !== '' && this.oldAnswers[key].custom === '') {
                newValue[key].answer = '';
              }
              if (newValue[key].answer.length > 0 && this.oldAnswers[key].answer.length === 0) {
                newValue[key].custom = '';
              }
            }
          }
        }

        this.oldAnswers = JSON.parse(JSON.stringify(newValue));
      },
      deep: true
    },
  },

  created() {
    this.$request.request().get('questionnaire').then((response) => {
      this.questions = response.data.recordset.questions;
      this.questions.forEach((item) => {
        this.answers[item.id] = {answer: [], custom: ''};
      })
      this.id = response.data.recordset.id;
      this.desc = response.data.recordset.desc;
    })
  },

  methods: {
    onSubmit() {
      let answers = {questionnaire_id: this.id, answers: []};
      for (let key in this.answers) {
        if (Object.hasOwnProperty.call(this.answers, key)) {
          if (this.answers[key].custom === '' && this.answers[key].answer.length === 0) {
            return false
          }

          let answer = {question_id: key, answer: []};
          if (this.answers[key].custom !== '') {
            answer.answer.push(this.answers[key].custom);
          }

          if ((typeof this.answers[key].answer) === "object") {
            this.answers[key].answer.forEach((item) => {
              answer.answer.push(item);
            })
          } else {
            if (this.answers[key].answer !== '')
              answer.answer.push(this.answers[key].answer.toString())
          }
          answers.answers.push(answer);
        }
      }

      this.$request.request(this.$member.token()).post('questionnaire/answer', {data: JSON.stringify(answers)}).then((response) => {
        // console.log(response)
        if (response.data.status > 0) {
          Toast(response.data.msg);
          return
        }
        this.complete = true;
        setTimeout(() => {
          this.complete = false;
          this.$router.push('/');
        }, 1000)
      })

      // console.log(JSON.stringify(answers));
      return false
    }
  }
}
</script>

<style scoped>

</style>