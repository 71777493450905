<template>
  <div id="detail">
    <div class="banner">
      <img :src="goods.detail_picture" alt=""/>
      <p>{{ goods.worth }}</p>
    </div>
    <div class="sale-info">
      <p class="title">{{ goods.name }}</p>
      <p class="price"><span class="total"><span class="coin-ico"></span>{{ goods.price }}积分</span></p>
    </div>
    <div class="input" v-show="goods.is_need_address">
      <input type="text" :placeholder="goods.address_tip" v-model="address" v-bind:style="{borderColor: showWarning}">
    </div>
    <div class="description">
      <p class="note">使用说明</p>
      <div class="content" v-html="goods.description"></div>
    </div>
    <div class="action">
      <button @click="confirm" v-bind:class="{disabled: goods.is_need_address && address === ''}">立即兑换</button>
    </div>

    <div class="mask" v-show="confirmExchange" v-on:click="cancel">
      <div class="exchange-confirm" @click.stop="cancel(index)">
        <div class="title">确认兑换</div>
        <div class="content">
          <input type="password" v-bind:class="{required: passwordEmpty}" v-model="password" placeholder="请输入密码"/>
          <p>为保证您的账号安全, 请输入登录密码</p>
          <p style="margin-bottom: 1.5rem;margin-top: 0" v-show="goods.is_need_address">请确保您提交的"{{address}}"正确</p>
        </div>
        <div class="exchange">
          <button @click="doExchange">立即兑换</button>
        </div>
      </div>
    </div>

    <div class="mask" v-show="exchangeState.complete">
      <div class="dialog">
        <div
            v-bind:class="{state: true, success: exchangeState.state === 'success', warning: exchangeState.state === 'warning', failed: exchangeState.state === 'failed'}">
          <div class="friendly-icon"></div>
          <p>{{ exchangeState.note }}</p>
        </div>
        <div class="tips">{{ exchangeState.tips }}</div>
      </div>
    </div>

    <div v-show="showDialog" class="mask">
      <div class="sale-out" v-html="dialogText"></div>
    </div>
  </div>
</template>

<script>
import "@/styles/detail.scss";
import Password from "../util/password";


export default {
  name: "Detail",

  data() {
    return {
      confirmExchange: false,
      password: '',
      passwordEmpty: false,
      showDialog: false,
      showWarning: '#26375a',
      address: '',
      dialogText: '',
      exchangeState: {
        state: 'success',
        note: '兑换成功',
        tips: '您已成功兑换引力高级会员7天',
        complete: false,
      },
      goods: {},
    }
  },

  created() {
    this.loadDetail(this.$router.currentRoute.value.query.id);
  },

  methods: {
    confirm() {
      if (this.goods.is_need_address && this.address === '') {
        this.showWarning = 'red';
        setTimeout(() => {
          this.showWarning = '#26375a'
        }, 1000)
        return
      }
      this.confirmExchange = true
    },
    cancel(event) {
      if (event !== undefined && event.currentTarget !== undefined) {
        if (event.currentTarget === event.target)
          this.confirmExchange = false
      }
    },

    loadDetail(id) {
      this.$request.request(this.$member.token()).get('goods/detail', {id: id}).then((response) => {
        this.goods = response.data.recordset;
      })
    },

    async doExchange() {
      if (this.password === '') {
        this.passwordEmpty = true;
        setTimeout(() => {
          this.passwordEmpty = false;
        }, 1000)
        return
      }

      let password = new Password(this.password);
      let hmac = await password.getHmac();
      this.$request.request(this.$member.token()).post('goods/exchange', {
        goods_id: this.goods.id,
        password: password.encrypt(hmac),
        hmac: hmac,
        address: this.address,
      }).then((response) => {
        if (response.data.status > 0) {
          this.showDialog = true;
          this.dialogText = response.data.msg;
          setTimeout(() => {
            this.showDialog = false;
          }, 1000);
        } else {
          this.exchangeState.complete = true;
          this.exchangeState.tips = `您已成功兑换${this.goods.name}`;
          this.confirmExchange = false;
          setTimeout(() => {
            this.exchangeState.complete = false;
          }, 1500)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>