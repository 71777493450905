<template>
  <div id="order-info">
    <div class="state">
      <div :class="'icon ' + states[goods.status]"></div>
      <p> {{ goods.status === 1 ? '奖励发放中' : '奖励已发放' }}</p>
    </div>
    <div class="order-id" @click="copy(goods.id)">
      <p>兑换号: {{ goods.id }} <i class="copy"></i></p>
    </div>
    <div class="banner">
      <img :src="goods.goods.detail_picture" alt="">
    </div>
    <div class="card-info" v-show="goods.cdk !== ''" @click="copy(goods.cdk)">
      <div class="name">卡密:</div>
      <div class="input">
        <input type="text" disabled v-model="goods.cdk">
        <i class="copy"></i>
      </div>
    </div>
    <div class="description">
      <p class="note">使用说明</p>
      <div class="content" v-html="goods.goods.description">
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/order-info.scss";
import {Toast} from "vant";

export default {
  name: "OrderInfo",

  created() {
    this.$request.request(this.$member.token()).get('goods/exchange/detail', {id: this.$router.currentRoute.value.query.id}).then((response) => {
      this.goods = response.data.recordset;
    })
  },

  data() {
    return {
      // state: 0,
      states: ['', 'delivering', 'delivered'],
      goods: {goods: {}},
    }
  },

  methods: {
    copy(value) {
      // let _this = this;
      let clipboard = (function (window, document, navigator) {
        let textArea,
            copy;

        function isOS() {
          return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
          textArea = document.createElement('textArea');
          textArea.innerHTML = text;
          textArea.value = text;
          document.body.appendChild(textArea);
        }

        function selectText() {
          let range, selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        }

        function copyToClipboard() {
          try {
            if (document.execCommand("Copy")) {
              // _this.followWechat.copyWechatIDSuccess = true;
              // setTimeout(() => {
              //   _this.followWechat.copyWechatIDSuccess = false
              // }, 1500)
              Toast('复制成功~');
            } else {
              console.log('1-复制失败！请手动复制！')
              //layer.msg('复制失败！请手动复制！');
            }
          } catch (err) {
            console.log('2-复制错误！请手动复制！')
            //layer.msg('复制错误！请手动复制！');

          }
          document.body.removeChild(textArea);
        }

        copy = function (text) {
          createTextArea(text);
          selectText();
          copyToClipboard();
        };

        return {
          copy: copy
        };
      })(window, document, navigator);

      clipboard.copy(value);
    },
  }
}
</script>

<style scoped>

</style>