import md5 from 'md5';
import request from "../request/request";

export default function Password(password) {
    this.password = password;

    this.getHmac = async function () {
        return await request.request().get('login/hmac').then((response) => {
            return response.data.recordset;
        });
    }

    this.encrypt = function (hmac) {
        console.log(hmac, this.password)
        return md5(hmac + md5('initap_' + this.password))
    }
}