<template>
  <div id="nav">
    <div class="header">
      <div>
        <div class="back" id="go-back" v-on:click="back()"></div>
      </div>
      <div class="title" id="main-title"></div>
      <div class="rule" id="activity-rule" @click="toggleShowRules">{{ $t("home.header.activity_rule") }}</div>
    </div>
  </div>
  <transition name="slide-right">
    <div class="container">
      <router-view/>
    </div>
  </transition>

  <div class="mask" v-show="showRules">
    <div id="rules-dialog">
      <div class="rule-content">
        <div class="content">
          <h3>活动规则</h3>
          <p>1. 签到时间以UTC/GMT+08:00为准</p>
          <p>2. 用户每日签到、做任务可获得积分，签到周期为7天，连续签到可获得额外奖励，积分可兑换微信红包、京东E卡、引力会员等各种奖励。</p>
          <p>3. 用户邀请好友，被邀请人每次付款购买高级会员，邀请人均可得到高额积分</p>
          <p>4. 奖励每日有库存量，若兑换未成功可改日再来兑换。</p>
          <p>5. 如发现有作弊、造假等行为，引力官方有权取消您所得的奖励。</p>
          <p>6. 该活动解释权归引力官方所有</p>
        </div>
        <button class="close" @click="toggleShowRules"></button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  font-family: PingFang SC;
}

body {
  background-color: #021B3A;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1234;
}

ul {
  list-style: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 0.5rem;
  background-color: #021B3A;
  height: 2.08rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .header {
    div {
      display: block;
      float: left;
      width: 33.3333%;
      height: 2.08rem;

      .back {
        background: url("/images/back.png") no-repeat left;
        background-size: 50%;
        height: 2.08rem;
      }
    }

    .title {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 2.08rem;
      text-align: center;
      opacity: 1;
    }

    .rule {
      font-size: 1rem;
      font-weight: 500;
      line-height: 2.08rem;
      color: #9B9EC6;
      opacity: 1;
      text-align: right;
    }
  }
}

.container {
  width: 94%;
  padding-right: 3%;
  padding-left: 3%;
  background-color: #021B3A;
  display: flex;
  display: -webkit-flex;
  margin: 3.08rem auto 0;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

</style>

<script>
import "@/styles/main.scss"

export default {
  name: 'Main',

  data() {
    return {
      showRules: false
    }
  },

  methods: {
    toggleShowRules() {
      this.showRules = !this.showRules;
    },
    back() {
      let platform = navigator.userAgent.indexOf('Android') > -1 ? 'android' : (navigator.userAgent.indexOf('iPhone') > -1 ? 'ios' : false);
      switch (this.$router.currentRoute.value.path) {
        case '/':
          switch (platform) {
            case 'android':
              window['android']['close']();
              break
            default:
              window['webkit']['messageHandlers']['close'].postMessage(null);
              break
          }
          break
        default:
          this.$router.back();
          break
      }
    }
  }

}
</script>