<template>
  <div id="points-log">
    <ul class="tabs">
      <li v-on:click="changeType('income')">积分 {{ points.total }}
        <div class="active" v-show="type === 'income'"></div>
      </li>
      <li v-on:click="changeType('expenditure')">兑换记录
        <div class="active" v-show="type === 'expenditure'"></div>
      </li>
    </ul>

    <van-list loading-text="加载中..." v-model="loading" offset="50" :finished="finished" finished-text="没有更多了"
              @load="onLoad" v-show="type === 'income'">
      <div class="item" v-for="(item) in pointsLogs.rows" :key="item.id" :title="item.id">
        <div class="info">
          <p class="title">{{ item.remark }}</p>
          <p class="datetime">{{ formatDate(item.created_at) }}</p>
        </div>
        <div class="amount">
          <p class="total"><span class="coin-ico"></span>{{ item.amount > 0 ? '+' + item.amount : item.amount }}</p>
        </div>
      </div>

      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item">-->
      <!--        <div class="info">-->
      <!--          <p class="title">用户签到</p>-->
      <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
      <!--        </div>-->
      <!--        <div class="amount">-->
      <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </van-list>

    <!--    <ul v-show="type === 'income'" class="income-list"  @scroll="browse">-->
    <!--      <li>-->
    <!--        <div class="info">-->
    <!--          <p class="title">用户签到</p>-->
    <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
    <!--        </div>-->
    <!--        <div class="amount">-->
    <!--          <p class="total"><span class="coin-ico"></span>+100</p>-->
    <!--        </div>-->
    <!--      </li>-->
    <!--    </ul>-->

    <van-list loading-text="加载中..." v-model="loading" offset="50" :immediate-check="true" :finished="finished"
              finished-text="没有更多了" @load="onLoad" v-show="type === 'expenditure'" class="expenditure-list">
      <div class="item" @click="detail(item)" v-for="(item) in exchangeLogs.rows" v-bind:key="item.id">
        <div class="info">
          <p class="order-id">兑换号: {{ item.id }}</p>
          <p class="title">{{ item.goods.name }}</p>
          <p class="datetime">{{ formatDate(item.created_at) }}</p>
        </div>
        <div class="amount">
          <p class="exchange-state">{{ status[item.status] }}</p>
          <p class="total"><span class="coin-ico"></span>-{{ item.point }}</p>
        </div>
      </div>
    </van-list>
    <!--    <ul v-show="type === 'expenditure'" class="expenditure-list">-->
    <!--      <li @click="detail()">-->
    <!--        <div class="info">-->
    <!--          <p class="order-id">兑换号: 412574196354125478</p>-->
    <!--          <p class="title">用户签到</p>-->
    <!--          <p class="datetime">2021-7-16 11:31:02</p>-->
    <!--        </div>-->
    <!--        <div class="amount">-->
    <!--          <p class="exchange-state">已发放</p>-->
    <!--          <p class="total"><span class="coin-ico"></span>-100</p>-->
    <!--        </div>-->
    <!--      </li>-->
    <!--    </ul>-->
  </div>
</template>

<script>
import "@/styles/points.scss";

export default {
  name: "PointsLog",

  data() {
    return {
      type: this.$router.currentRoute.value.query.type,
      loading: false,
      finished: false,
      points: {
        today: 0,
        total: 0
      },
      page: 1,
      pointsLogs: {rows: [], page: 0},
      exchangeLogs: {rows: [], page: 0},
      status: ['', '发放中', '已发放'],
    }
  },

  created() {
    this.$member.points((data) => {
      this.points = data['recordset']
    });
    // this.loadPointsLog();
  },

  methods: {
    formatDate(time) {
      let date = new Date(time * 1000);
      let year = date.getFullYear(),
          month = (date.getMonth() + 1),
          days = date.getDate(),
          hours = date.getHours(),
          minutes = date.getMinutes(),
          seconds = date.getSeconds();
      return year + '-' +( month > 9 ? month : '0'+month) + '-' + (days > 9 ? days : '0' + days) + ' ' + (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds)
    },

    changeType(type) {
      this.type = type;
      this.page = 0;
      this.pointsLogs = {rows: [], page: 0};
      this.exchangeLogs = {rows: [], page: 0};
      this.finished = false;
      this.loading = false;

      switch (this.type) {
        case 'income':
          this.loadPointsLog(this.page += 1, () => {
            setTimeout(() => {
              this.loading = false;
            }, 1000)
          })
          break
        case 'expenditure':
          this.loadExchangeLog(this.page += 1, () => {
            setTimeout(() => {
              this.loading = false;
            }, 1000)
          })
      }
    },
    detail(item) {
      this.$router.push('/order/info?id=' + item.id);
    },

    loadPointsLog(page, cb) {
      page = page === undefined ? 1 : page;
      let rows = 20;
      this.$request.request(this.$member.token()).get('member/award_log', {
        page: page,
        row: rows,
        sort: '-updated_at'
      }).then((response) => {
        if (response.data.recordset.page > this.pointsLogs.page) {
          response.data.recordset.rows.forEach((item) => {
            this.pointsLogs.rows.push(item)
          })

          this.pointsLogs.total = response.data.recordset.total;
          this.pointsLogs.page = response.data.recordset.page;
          this.finished = Math.ceil(this.pointsLogs.total / rows) <= page;
          if (cb !== undefined)
            cb();
        }
      })
    },

    loadExchangeLog(page, cb) {
      page = page === undefined ? 1 : page;
      let rows = 20;
      this.$request.request(this.$member.token()).get('goods/exchange', {
        page: page,
        row: rows,
        sort: '-updated_at'
      }).then((response) => {
        console.log(response.data.recordset.page, this.exchangeLogs.page);
        if (response.data.recordset.page > this.exchangeLogs.page) {
          response.data.recordset.rows.forEach((item) => {
            this.exchangeLogs.rows.push(item);
            this.exchangeLogs.total = response.data.recordset.total;
            this.exchangeLogs.page = response.data.recordset.page;
            this.finished = Math.ceil(this.exchangeLogs.total / rows) <= page;
            if (cb !== undefined)
              cb();
          })
        }
      })
    },

    onLoad() {
      if (!this.loading) {
        this.loading = !this.loading;
        switch (this.type) {
          case 'income':
            this.loadPointsLog(this.page++, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break
          case 'expenditure':
            this.loadExchangeLog(this.page++, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
        }
      }
      // setTimeout(() => {
      // for (let i = 0; i < 10; i++) {
      //   this.list.push(this.list.length + 1);
      // }

      // 加载状态结束
      //   this.loading = false;
      //   // alert('加载完成')
      //   // 数据全部加载完成
      //   // if (this.list.length >= 40) {
      //   //   this.finished = true;
      //   // }
      // }, 2000);
    },
  }
}
</script>

<style scoped>

</style>