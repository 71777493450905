import "../request/request"
import request from "../request/request";

export default function Member() {

    this.token = function () {
        return localStorage.getItem('user-token');
    }

    this.setToken = function (token) {
        localStorage.setItem('user-token', token);
    }

    this.delToken = function () {
        localStorage.removeItem('user-token');
    }

    this.member = null;

    this.load = function (onSuccess, onUnauthorized) {
        request.request(this.token()).get('member/info').then((response) => {
            if (response.data.status === 0) {
                this.member = response.data['recordset'];
                if (onSuccess !== undefined) {
                    onSuccess(this.member);
                }
            } else {
                this.member.set(null);
                if (onUnauthorized !== undefined) {
                    onUnauthorized(this.member);
                }
            }
        }).catch(() => {
            if (onUnauthorized !== undefined) {
                onUnauthorized(this.member);
            }
        })
    }

    this.points = function (cb) {
        request.request(this.token()).get('member/award').then((response) => {
            cb(response.data)
        })
    }

    // this.load();
}