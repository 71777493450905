<template>
  <iframe :src="invite"></iframe>
</template>

<style scoped lang="scss">
iframe {
  border: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  position: fixed;
  &::-webkit-scrollbar {
    display: none;
  }
}

</style>

<script>
export default {
  name: "Invite",

  data() {
    return {
      invite: 'https://www.initap.com/activity/index.html?token=' + this.$member.token(),
    }
  },
}
</script>

<style scoped>

</style>